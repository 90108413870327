@mixin subtitle {
  color: #888;
  font-size: 14px;
  margin: 0 auto 20px auto;
  width: 100%;
  max-width: 500px;
  
  a {
    color: #888;
  }
}

@mixin elevated {
  border: 1px solid #282828;
  background: #282828;
  box-shadow: 0 1px 3px rgba(0,0,0,0.5);
}

$app-background-color: #121212;
$min-artwork-dimension: 280px;

.App {
  text-align: center;
  background-color: $app-background-color;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  
  .top-bar {
    width: 100%;
    height: 1em;
    background: #282828;
  }
  
  .header-container {
    background-color: $app-background-color;
    margin: 0 5% 0 5%;
    padding-top: 12%;

    .app-header {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-evenly;

      .title-header {
        min-height: 10vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: white;
  
        .title {
          text-transform: uppercase;
          font-size: calc(20px + 3vmin);
          font-weight: bold;
          margin: 15px 0 9px 0;
          color: white;
          text-decoration: none;
  
          &:hover {
            text-decoration: none;
          }
        }
  
        .subtitle {
          @include subtitle;
        }
  
        .subscribe {
          padding: 0 0 8px 0;
          margin: 0 0 16px 0;
          border-radius: 15px;
          @include elevated();
  
          .subscribe-title {
            color: #869AB3;
            padding: 10px 5px 10px 5px;
            text-transform: uppercase;
            font-size: 0.75em;
          }
  
          .subscribe-badges {
            display: flex;
            flex-direction: row;
            justify-content: center;
            padding: 0 5px 0 5px;
  
            .subscribe-badge {
              display: flex;
              flex-direction: column;
              width: 68px;
              align-items: center;
              text-decoration: none;
  
              .img-container {
                overflow: hidden;
                height: 48px;
                border-radius: 15px;
                padding-bottom: 3px;
                
                .badge-img {
                  width: 48px;
                  border-radius: 15px;
                } 
              }
  
              .app-name {
                font-size: 0.75em;
                color: hsl(213, 64.3%, 72.0%);
              }
            }
          }
        }
      }

      .landing {
        align-self: center;
        min-width: $min-artwork-dimension;
        min-height: $min-artwork-dimension;
        width: 25vw;
        height: 25vw;
        border-radius: 3%;

        .artwork {
          border-radius: 3%;
          min-width: $min-artwork-dimension;
          min-height: $min-artwork-dimension;
          width: 100%;
          height: 25vw;
          transition: all .2s ease;
          
          &:hover {
            -webkit-box-shadow: 0 5px 15px rgba(165, 163, 163, .3);  /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
            -moz-box-shadow:    0 5px 15px rgba(165, 163, 163, .3);  /* Firefox 3.5 - 3.6 */
            box-shadow: 0 5px 15px rgba(165, 163, 163, .3); /* Opera 10.5, IE 9, Firefox 4+, Chrome 6+, iOS 5 */
            transform: scale(1.03);
          }
        }
      }
    }

    .nav-bar {
      margin-top: 40px;
      border-top: 4px solid hsl(213, 3.1%, 24.3%);
      border-bottom: 4px solid hsl(213, 3.1%, 24.3%);
      width: 100%;
      margin-bottom: 64px;

      ul {
        list-style: none;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin: 12px 0 12px 0;
        padding: 0;

        li {
          padding: 0 15px 0 15px;

          a {
            color: rgb(138, 179, 230);
            font-size: calc(8px + 1vmin);
            text-decoration: none;
            text-transform: uppercase;
            font-weight: 700;

            &.selected {
              text-decoration: underline;
              color: hsl(213, 100.0%, 90.0%);
            }

            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
    }

    .feedback {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      color: white;
      text-align: left;
    }
  }
  
  .feed-container {
    background-color: $app-background-color;
    padding: 25px;
    margin: 0 auto;
    .feed {
      .post {
        padding: 0 0 64px 0;

        h2 {
          color: hsl(213, 100.0%, 90.0%);
        }
        .metadata {
          color: #888;
          a {
            color: hsl(213, 64.3%, 72.0%);
          }
        }
        audio {
          width: 100%;
          max-width: 500px;
          margin: 1em auto;
        }
        .subtitle {
          @include subtitle;
          text-align: left;
        }
        .show-notes {
          margin: 0 auto 0 auto;
          width: 100%;
          max-width: 500px;
          text-align: left;
          color: white;
          a {
            color: rgba(255, 255, 255, .85);
          }
        }
        ul {
          color: rgba(255, 255, 255, .85);;
          text-align: left;
          // TODO: We're not showing list items at the moment, but if we do, this wrapping style looks terrible
          li {
            height: 30px;
          }
        }
      }
    }
  }
}